import { isPosten } from '@glow/common/utils/env'
import { Links, ThemeAdvertisement, ThemeColors, ThemeImages } from '@glow/entity-types'
import React, { createContext, ReactElement, ReactNode, useEffect, useMemo, useState } from 'react'
import { ThemeV2 } from '../../trackdelivery/domain/Theme'
import {
  checkImageExists,
  CUSTOM_THEME,
  getTheme,
  getThemeVariables,
  setColors,
  setThemeInStorage,
  Theme,
  themeColorsFromStorage,
  themeFromParam
} from '../utils/theme'

interface PartialConsignment {
  themeV2?: ThemeV2
  theme?: Theme
  orderType?: string
}

enum ThemeState {
  UseQueryParams,
  UseCustomTheme,
  UseFallbackTheme,
  WaitingForTheme,
  Preview
}

interface ThemeContextValue {
  theme: Theme
  isCustomTheme: boolean
  isPostenHD: boolean
  themeV2?: ThemeV2
  isLoading: boolean
  isPreviewTheme: boolean
  updateTheme: (partialConsignment: PartialConsignment, previewScenario?: string | null) => void
}

export const ThemeContext = createContext<ThemeContextValue>({
  theme: 'glow',
  isPostenHD: false,
  isCustomTheme: false,
  isPreviewTheme: false,
  isLoading: true,
  updateTheme: () => {}
})

const iframeOriginWhitelist = ['localhost:10002', 'qa.glow.bring.services', 'glow.bring.com', 'glowtechnology.com']

export const ThemeProvider = (props: { children: ReactNode }): ReactElement => {
  const [theme, setTheme] = useState<Theme>(() => getTheme())
  const [themeV2, setThemeV2] = useState<ThemeV2>()
  const [themeState, setThemeState] = useState<ThemeState>(ThemeState.WaitingForTheme)
  const [isUnmounted, setIsUnmounted] = useState(false)
  const [isPostenHD, setIsPostenHD] = useState(false)

  useEffect(() => {
    let timeoutId: NodeJS.Timeout
    if (themeState === ThemeState.WaitingForTheme) {
      timeoutId = setTimeout(() => {
        if (!isUnmounted && themeState === ThemeState.WaitingForTheme) {
          setThemeState(ThemeState.UseFallbackTheme)
        }
      }, 3000)
    }

    return () => clearTimeout(timeoutId)
  }, [themeState])

  useEffect(() => {
    const onMessage = (event: MessageEvent) => {
      if (!iframeOriginWhitelist.some((x) => event.origin.includes(x))) {
        // If message doesn't come from whitelist origin, them abort
        return
      }
      try {
        const eventData = event.data ? JSON.parse(event.data) : undefined
        const colors: ThemeColors = eventData ? eventData.colors : undefined
        const images: ThemeImages = eventData ? eventData.images : {}
        const links: Links = eventData ? eventData.links : undefined
        const advertisement: ThemeAdvertisement = eventData ? eventData.advertisement : undefined
        const themeV2: ThemeV2 = { colors, links, images, advertisement }

        setThemeState(ThemeState.Preview)
        setThemeV2(themeV2)
        if (themeV2?.colors) {
          setColors(themeV2?.colors, 'custom-theme')
          setTheme('custom-theme')
        }
      } catch (error) {
        console.error('Iframe from tracking onMessage event failed', error)
      }
    }
    window.addEventListener('message', onMessage)

    return () => {
      window.removeEventListener('message', onMessage)
    }
  }, [])

  const setColorsOnMount = () => {
    const themeQueryParam = themeFromParam()
    const savedThemeColors = themeColorsFromStorage()

    if (themeQueryParam) {
      setColors(getThemeVariables(themeQueryParam), themeQueryParam)
      setTheme(themeQueryParam)
      setThemeState(ThemeState.UseQueryParams)
    } else if (savedThemeColors) {
      setColors(savedThemeColors, CUSTOM_THEME)
      setTheme(CUSTOM_THEME)
      setThemeState(ThemeState.UseCustomTheme)
    }
  }

  const setThemeIfImagesAreValid = async (
    partialConsignment: PartialConsignment | null,
    previewScenario?: null | string
  ) => {
    if (partialConsignment?.theme) {
      setThemeColors(partialConsignment, previewScenario)
    } else if (partialConsignment?.themeV2?.images) {
      try {
        await Promise.all(
          Object.values(partialConsignment?.themeV2?.images).map((image: string) => checkImageExists(image))
        )
        setThemeColors(partialConsignment, previewScenario)
      } catch (error) {
        console.error(error)
        // Fallback to default theme or previewScenario
        setThemeColors(null, previewScenario)
      }
    } else {
      // If no images default theme or previewScenario
      setThemeColors(null, previewScenario)
    }
  }

  const updateTheme = (partialConsignment: PartialConsignment | null, previewScenario?: null | string) => {
    if (themeState === ThemeState.UseQueryParams) {
      // Skip if theme comes from query param
      return
    }
    if (isUnmounted) {
      return
    }
    checkAndSetPostenIsHD(partialConsignment)
    setThemeIfImagesAreValid(partialConsignment, previewScenario)
  }

  const checkAndSetPostenIsHD = (partialConsignment: PartialConsignment | null) => {
    if (isPosten() && partialConsignment?.orderType === 'HD') {
      setIsPostenHD(true)
    } else {
      setIsPostenHD(false)
    }
  }

  const setThemeColors = (partialConsignment: PartialConsignment | null, previewScenario?: null | string) => {
    if (previewScenario) {
      setThemeState(ThemeState.Preview)
      setTheme(getTheme())
    } else if (partialConsignment?.themeV2) {
      setThemeState(ThemeState.UseCustomTheme)
      setThemeV2(partialConsignment.themeV2)
      setTheme(CUSTOM_THEME)
      if (partialConsignment?.themeV2?.colors) {
        setColors(partialConsignment?.themeV2?.colors, CUSTOM_THEME)
      }
    } else if (partialConsignment?.theme) {
      const themeName = partialConsignment.theme
      const themeColors = getThemeVariables(themeName)
      setThemeState(ThemeState.UseFallbackTheme)
      setColors(themeColors, themeName)
      setTheme(themeName)
      setThemeInStorage(themeName, themeColors)
    } else {
      setThemeState(ThemeState.UseFallbackTheme)
      setColors(getThemeVariables(getTheme()), getTheme())
      setTheme(getTheme())
    }
  }

  useEffect(() => {
    setColorsOnMount()
    return () => {
      setIsUnmounted(true)
    }
  }, [])

  const value: ThemeContextValue = useMemo(
    () => ({
      theme,
      isCustomTheme: theme === 'custom-theme',
      isPostenHD,
      themeV2,
      isLoading: themeState === ThemeState.WaitingForTheme,
      isPreviewTheme: themeState === ThemeState.Preview,
      updateTheme
    }),

    [theme, themeState, updateTheme]
  )
  return <ThemeContext.Provider value={value}>{props.children}</ThemeContext.Provider>
}
