import React, { useContext } from 'react'
import { useHref } from 'react-router-dom'
import ikeaLogo from '../../assets/icons/ikea/ikea-logo.svg'
import bringLogo from '../../assets/icons/logos/bring-logo.svg'
import glowLogo from '../../assets/icons/logos/glow-logo.svg'
import miistLogo from '../../assets/icons/logos/miist-logo.png'
import postenLogo from '../../assets/icons/logos/posten-logo.svg'
import { ThemeContext } from '../contexts/ThemeContext'
import { Theme } from '../utils/theme'

interface LogoProps {
  // if no homeUrl is provided it will default to the current route
  homeUrl?: string
}

export function Logo({ homeUrl = '.' }: LogoProps) {
  const href = useHref(homeUrl)

  return (
    <figure>
      <a href={href}>
        <LogoImg />
      </a>
    </figure>
  )
}

function LogoImg() {
  const { theme, themeV2, isLoading } = useContext(ThemeContext)

  if (isLoading) return <></>

  return themeV2?.images?.logo ? <CustomLogo logoSrc={themeV2?.images?.logo} /> : <DefaultLogo theme={theme} />
}

function CustomLogo({ logoSrc }: { logoSrc: string }) {
  return <img src={logoSrc} alt="Custom logo" />
}

export function DefaultLogo({ theme }: { theme: Theme }) {
  switch (theme) {
    case 'bring':
      return <BringLogoImg />
    case 'posten':
      return <PostenLogoImg />
    case 'ikea':
    case 'ikea-dk':
      return <IkeaLogoImg />
    default:
      return <GlowLogoImg />
  }
}

export function BringLogoImg() {
  return <img src={bringLogo} alt="Bring logo" />
}

export function PostenLogoImg() {
  return <img src={postenLogo} alt="Posten logo" />
}

function IkeaLogoImg() {
  return <img src={ikeaLogo} alt="IKEA logo" />
}

export function GlowLogoImg() {
  return <img src={glowLogo} alt="Glow logo" />
}

export function MiistLogoImg() {
  return <img src={miistLogo} alt="Miist logo" />
}
